'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useClickAway, usePrevious } from 'react-use';
import { useMount } from 'react-use';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import Portal from 'components/ui/Portal';
import Spinner from 'components/ui/Spinner';
import SvgIcon from 'components/ui/SvgIcon';

import styles from './DestiniBuyButton.module.scss';

const DestiniBuyButton = ({ sku, button, disableScroll }) => {
    disableScroll ??= true;

    const [isOpen, setIsOpen] = useState(false);
    const [isLoaded, setLoaded] = useState(false);

    const prevIsOpen = usePrevious(isOpen);
    const destiniDiv = useRef(null);
    const overlayRef = useRef(null);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        enableBodyScroll(overlayRef?.current);
        setIsOpen(false);
        setLoaded(false);
    };

    const ref = useRef(null);

    useMount(() => {
        window.addEventListener(
            'message',
            event => {
                if (/^{"eventCategory":"DESTINI_LOAD"/gi.test(event.data)) {
                    setTimeout(() => setLoaded(true), 1000);
                }
            },
            false
        );
    });

    useClickAway(ref, () => {
        enableBodyScroll(overlayRef?.current);
        setIsOpen(false);
    });

    useEffect(() => {
        if (!window.destini) {
            // maybe cause GTM id is undefined in dev env
            console.log(`Cannot find Destini:`, window.destini);
            return;
        }

        if (isOpen && isOpen !== prevIsOpen) {
            window.destini?.init('destinidiv');
            window.destini?.loadWidget('default', sku);

            if (disableScroll && overlayRef?.current) {
                disableBodyScroll(overlayRef?.current, {
                    allowTouchMove: el => {
                        while (el && el !== document.body) {
                            if (
                                el.getAttribute('body-scroll-lock-ignore') !==
                                null
                            ) {
                                return true;
                            }

                            el = el.parentElement;
                        }
                    },
                });
                document.body.style.setProperty(
                    'top',
                    `${window.scrollY * -1}px`
                ); // fix for scroll-to-top body-scroll-lock bug: https://github.com/willmcpo/body-scroll-lock/issues/237#issuecomment-954804479
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, prevIsOpen, disableScroll]);

    if (!sku || !button) {
        return null;
    }

    return (
        <>
            {React.cloneElement(button, { onClick: handleOpen })}
            <Portal selector="#__tertiary_portal__">
                {isOpen && (
                    <div
                        ref={overlayRef}
                        className={cx(styles.destiniOverlay, {
                            [styles.destiniOverlayIsOpen]: isOpen,
                        })}
                    >
                        <div
                            className={styles.destiniOverlayInnerWrap}
                            ref={ref}
                        >
                            {!isLoaded && (
                                <div className={styles.loadingOverlay}>
                                    <Spinner />
                                </div>
                            )}
                            <div
                                className={cx(
                                    styles.destiniOverlayBackdrop,
                                    styles.transparent
                                )}
                                onClick={handleClose}
                                role="presentation"
                            />
                            <Button
                                className={styles.destiniOverlayCloseIcon}
                                theme="close"
                                onClick={handleClose}
                            >
                                <SvgIcon type="close" />
                                <span className="sr-only">Close Product</span>
                            </Button>
                            <div
                                className={styles.destiniIframeDiv}
                                ref={destiniDiv}
                                id="destinidiv"
                            ></div>
                        </div>
                    </div>
                )}
            </Portal>
        </>
    );
};

DestiniBuyButton.propTypes = {
    button: PropTypes.element.isRequired,
    sku: PropTypes.array.isRequired,
    disableScroll: PropTypes.bool,
};

export default DestiniBuyButton;

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import Button from 'components/ui/Button';
import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './Overlay.module.scss';

const copyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    className={styles.copy}
                    baseTheme="bodyMedium"
                    themes={{ xLarge: 'bodyLarge' }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const Overlay = ({
    eyebrow,
    headline,
    bodyCopy,
    copy,
    image,
    image2 = null,
    cta,
    theme,
    setIsOpen = () => {},
    isOpen = false,
    isActive = false,
}) => {
    return (
        <div
            className={cx(styles.root, {
                [styles.isOpen]: isActive && isOpen,
                [styles.isClosing]: !isActive || !isOpen,
                [styles[`theme-${theme}`]]: theme,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.contentWrapper}>
                    <div>
                        {eyebrow && (
                            <Text
                                as="p"
                                className={styles.eyebrow}
                                baseTheme="labelMedium"
                                themes={
                                    theme === 'shreds'
                                        ? { medium: 'labelLarge' }
                                        : { large: 'labelLarge' }
                                }
                            >
                                {eyebrow}
                            </Text>
                        )}
                        {headline && (
                            <Text
                                as="h2"
                                className={styles.headline}
                                baseTheme="displaySmall"
                                themes={{ xLarge: 'displayMedium' }}
                            >
                                {headline}
                            </Text>
                        )}
                        {copy && (
                            <Text
                                as="p"
                                className={styles.copy}
                                baseTheme="bodyMedium"
                                themes={{ xLarge: 'bodyLarge' }}
                                fixWidows
                            >
                                {copy}
                            </Text>
                        )}
                        {bodyCopy && (
                            <RichText
                                richText={bodyCopy}
                                overrides={copyOverrides}
                            />
                        )}
                    </div>
                    {image && (
                        <div
                            className={cx(styles.imageWrapper, {
                                [styles['image-square']]: image2,
                            })}
                        >
                            <Img
                                className={styles.image}
                                src={image?.url}
                                fallbackImageWidth={768 * 1.5}
                                alt={''}
                                customSources={[
                                    {
                                        src: image?.url,
                                        imageWidth: 768 * 1.5,
                                    },
                                ]}
                            />
                            {image2 && (
                                <Img
                                    className={styles.image}
                                    src={image2?.url}
                                    fallbackImageWidth={768 * 1.5}
                                    alt={''}
                                    customSources={[
                                        {
                                            src: image2?.url,
                                            imageWidth: 768 * 1.5,
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    )}
                    {cta && <ButtonCTA className={styles.cta} {...cta} />}
                </div>
            </div>
            <Button
                theme="none"
                onClick={() => setIsOpen(false)}
                className={styles.closeButton}
            >
                <span className="sr-only">Close</span>
                <SvgIcon className={styles.closeIcon} type="close" />
            </Button>
        </div>
    );
};

Overlay.propTypes = {
    image: PropTypes.object,
    image2: PropTypes.object,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    bodyCopy: PropTypes.object,
    copy: PropTypes.string,
    cta: PropTypes.object,
    theme: PropTypes.string,
    isOpen: PropTypes.bool,
    isActive: PropTypes.bool,
    setIsOpen: PropTypes.func,
};

export default Overlay;

'use client';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import useDocumentVisibility from 'hooks/useDocumentVisibility';

import CarouselContext from './CarouselContext';
import { MODE } from './carouselConstants';

import styles from './CarouselProvider.module.scss';

const { PAUSED, PLAYING, CONTINUE, STOPPED } = MODE;

const CarouselProvider = ({
    slides = [],
    timerDuration = 7,
    isContinuous = false,
    auto = true,
    className,
    children,
}) => {
    const cleanSlides =
        slides?.filter(slide => {
            return slide.__typename !== undefined;
        }) || [];

    const ref = useRef(null);
    const documentVisibility = useDocumentVisibility();

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    const [mode, setMode] = useState(PAUSED);
    const [activeIndex, setActiveIndex] = useState(null);
    const [prevIndex, setPrevIndex] = useState(null);
    const [seconds, setSeconds] = useState(0);
    const [whileInterrupt, setWhileInterrupt] = useState(false);

    const totalSlides = cleanSlides.length;

    const nextSlide = useCallback(() => {
        setPrevIndex(activeIndex);
        setActiveIndex((activeIndex + 1) % totalSlides);
    }, [activeIndex, totalSlides]);

    const prevSlide = useCallback(() => {
        setPrevIndex(activeIndex);
        setActiveIndex((activeIndex - 1 + totalSlides) % totalSlides);
    }, [activeIndex, totalSlides]);

    const slideChange = useCallback(
        direction => {
            direction === 'prev' ? prevSlide() : nextSlide();
        },
        [nextSlide, prevSlide]
    );

    const handleClick = direction => () => {
        slideChange(direction);

        // Allows for different animation when interrupted
        setWhileInterrupt(true);

        if (isContinuous) {
            // Auto advance carousel even if user interrupts
            setMode(CONTINUE);
        } else {
            // Don't auto advance carousel if user interrupts
            setMode(STOPPED);
        }
    };

    useEffect(() => {
        let interval = null;

        switch (mode) {
            case PAUSED:
                break;
            case PLAYING:
                // Interval clock that guides everything
                // related to the auto rotation of slides
                interval = setInterval(() => {
                    if (seconds === timerDuration) {
                        setSeconds(0);
                        slideChange('next');
                    } else {
                        setSeconds(seconds => seconds + 1);
                        setWhileInterrupt(false);
                    }
                }, 1000);
                break;
            case CONTINUE:
                setSeconds(0);
                break;
            case STOPPED:
                setSeconds(0);
                break;
        }
        return () => {
            clearInterval(interval);
        };
    }, [mode, seconds, slideChange, timerDuration]);

    useEffect(() => {
        const inView =
            documentVisibility === 'visible' && intersection?.isIntersecting;

        if (mode !== STOPPED) {
            // Run intro animation once
            inView && activeIndex === null && setActiveIndex(0);

            // Toggle timer when in and out of view
            inView && auto ? setMode(PLAYING) : setMode(PAUSED);
        }
    }, [
        activeIndex,
        auto,
        documentVisibility,
        intersection?.isIntersecting,
        mode,
    ]);

    return (
        <section ref={ref} className={cx(styles.root, className)}>
            <CarouselContext.Provider
                value={{
                    activeIndex,
                    prevIndex,
                    totalSlides,
                    mode,
                    seconds,
                    slides: cleanSlides,
                    isContinuous,
                    whileInterrupt,
                    timerDuration,
                    nextSlide: handleClick('next'),
                    prevSlide: handleClick('prev'),
                }}
            >
                {children}
            </CarouselContext.Provider>
        </section>
    );
};

CarouselProvider.propTypes = {
    auto: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    isContinuous: PropTypes.bool,
    slides: PropTypes.array,
    timerDuration: PropTypes.number,
};

export default CarouselProvider;

'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Icon from 'components/ui/Icon';
import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import { blockRootProps } from 'utils';
import { scrollToOffset } from 'utils';

import BubbleButton from './BubbleButton';
import Overlay from './Overlay';

import styles from './BlockHotspot.module.scss';

const copyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    className={styles.copy}
                    baseTheme="bodyMedium"
                    themes={{ xLarge: 'bodyLarge' }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const BlockHotspot = ({
    __typename,
    eyebrow,
    preHeadline,
    headline,
    bodyCopy,
    copy,
    cta,
    labels,
    image,
    imageLarge,
    textColor,
    hotspotButtonColor,
    flushTop,
    flushBottom,
    hotspots,
    layout,
    theme,
    icon,
    anchorId,
}) => {
    textColor ??= 'blue';
    hotspotButtonColor ??= 'blue';
    flushTop ??= true;
    flushBottom ??= true;
    layout ??= 'backgroundImage';

    const isHexButtonColor = hotspotButtonColor.includes('#');

    const rootRef = useRef(null);

    const [isOverlayOpen, toggleOverlayOpen] = useState(false);
    const [currentHotspot, setCurrentHotspot] = useState(0);
    const [isInView, setIsInView] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    const intersection = useIntersection(rootRef, {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    });

    const handleHotspotClick = e => {
        const index = e.currentTarget.getAttribute('data-index');
        setCurrentHotspot(parseInt(index));
        toggleOverlayOpen(true);

        scrollToOffset(rootRef?.current, -120, 'smooth');
    };

    useEffect(() => {
        if (intersection?.isIntersecting) {
            setIsInView(true);
        }
    }, [intersection]);

    useEffect(() => {
        // for hotspot button coordinates hydration mismatch
        setIsMounted(true);
    }, []);

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.root, {
                [styles.isInView]: isInView,
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.halfImageLayout]: layout === 'halfImage',
                [styles[`theme-${theme}`]]: theme,
            })}
            ref={rootRef}
        >
            <div className={styles.inner}>
                <div className={styles.interactive}>
                    {labels && <div className={styles.labels}>{labels}</div>}
                    {image && (
                        <Img
                            className={cx({
                                [styles.backgroundImage]:
                                    layout === 'backgroundImage',
                                [styles.halfImage]: layout === 'halfImage',
                            })}
                            src={image?.url}
                            fallbackImageWidth={768}
                            alt={image?.url || ''}
                            customSources={[
                                {
                                    breakpoint: 1920,
                                    src: imageLarge?.url,
                                    imageWidth: 1920 * 1.5,
                                },
                                {
                                    breakpoint: 1440,
                                    src: imageLarge?.url,
                                    imageWidth: 1440 * 1.5,
                                },
                                {
                                    breakpoint: 1024,
                                    src: imageLarge?.url,
                                    imageWidth: 1024 * 1.5,
                                },
                                {
                                    breakpoint: 768,
                                    src: imageLarge?.url,
                                    imageWidth: 1024,
                                },
                                {
                                    src: image?.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    )}
                    {isMounted && (
                        <div className={styles.buttons}>
                            {hotspots?.map(
                                (
                                    { headline, position, desktopPosition },
                                    i
                                ) => {
                                    // Check for positions
                                    if (position && desktopPosition) {
                                        const coordinates = new RegExp(
                                            /^\[(?<x>[0-9]{1,2}),(?<y>[0-9]{1,2})\]$/
                                        );
                                        // Check that positions match our pattern
                                        if (
                                            coordinates.test(position) &&
                                            coordinates.test(desktopPosition)
                                        ) {
                                            const mobile =
                                                coordinates.exec(
                                                    position
                                                )?.groups;

                                            const desktop =
                                                coordinates.exec(
                                                    desktopPosition
                                                )?.groups;
                                            return (
                                                <div
                                                    key={i}
                                                    className={
                                                        styles.hotspotButton
                                                    }
                                                    style={{
                                                        '--pos-y': `${mobile.y}%`,
                                                        '--pos-x': `${mobile.x}%`,
                                                        '--desktop-pos-y': `${desktop.y}%`,
                                                        '--desktop-pos-x': `${desktop.x}%`,
                                                        '--hotspot-color':
                                                            isHexButtonColor
                                                                ? hotspotButtonColor
                                                                : `var(--colors-${hotspotButtonColor})`,
                                                        '--stagger-order': i,
                                                        '--pulse-order':
                                                            (i + 1) *
                                                            Math.random(),
                                                    }}
                                                >
                                                    <BubbleButton
                                                        data-index={i}
                                                        gtm-label={`hotspot - ${headline}`}
                                                        onClick={
                                                            handleHotspotClick
                                                        }
                                                    />
                                                </div>
                                            );
                                        }
                                    }
                                }
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.contentInner}>
                        <div
                            className={cx(styles.content, {
                                [styles.hide]: isOverlayOpen,
                            })}
                            style={{
                                '--textColor': `var(--colors-${textColor})`,
                            }}
                        >
                            {icon?.icon?.url && (
                                <div className={styles.icon}>
                                    <Icon src={icon.icon.url} />
                                </div>
                            )}
                            {eyebrow && (
                                <Text
                                    as="p"
                                    className={styles.eyebrow}
                                    baseTheme="labelMedium"
                                    themes={{
                                        large: 'labelLarge',
                                    }}
                                >
                                    {eyebrow}
                                </Text>
                            )}
                            {preHeadline && (
                                <Text
                                    as="h3"
                                    className={styles.preHeadline}
                                    baseTheme="displaySmall"
                                    themes={{ large: 'displayLarge' }}
                                >
                                    {preHeadline}
                                </Text>
                            )}
                            {headline && (
                                <Text
                                    as="h2"
                                    className={cx(styles.headline, {
                                        [styles['headline-block']]:
                                            theme === 'slices',
                                    })}
                                    baseTheme={
                                        preHeadline
                                            ? 'displayMedium'
                                            : 'displaySmall'
                                    }
                                    themes={
                                        preHeadline
                                            ? { large: 'displayXLarge' }
                                            : theme === 'holiday'
                                            ? { xLarge: 'displayLarge' }
                                            : { xLarge: 'displayMedium' }
                                    }
                                    fixWidows
                                >
                                    {headline}
                                </Text>
                            )}
                            {copy && (
                                <Text
                                    as="p"
                                    className={styles.copy}
                                    baseTheme="bodyMedium"
                                    themes={{ large: 'bodyLarge' }}
                                    fixWidows
                                >
                                    {copy}
                                </Text>
                            )}
                            {bodyCopy && (
                                <RichText
                                    richText={bodyCopy}
                                    overrides={copyOverrides}
                                />
                            )}
                            {cta?.__typename === 'Cta' && (
                                <ButtonCTA className={styles.cta} {...cta} />
                            )}
                        </div>
                        {hotspots.map((hotspot, i) => {
                            return (
                                i === currentHotspot && (
                                    <Overlay
                                        key={i === currentHotspot}
                                        isOpen={isOverlayOpen}
                                        setIsOpen={toggleOverlayOpen}
                                        isActive={i === currentHotspot}
                                        theme={theme}
                                        {...hotspot}
                                    />
                                )
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

BlockHotspot.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    bodyCopy: PropTypes.object,
    copy: PropTypes.string,
    cta: PropTypes.object,
    eyebrow: PropTypes.string,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    headline: PropTypes.string,
    hotspotButtonColor: PropTypes.string,
    hotspots: PropTypes.array,
    icon: PropTypes.shape({
        icon: PropTypes.shape({
            url: PropTypes.any,
        }),
    }),
    image: PropTypes.object,
    imageLarge: PropTypes.object,
    labels: PropTypes.array,
    layout: PropTypes.string,
    preHeadline: PropTypes.string,
    textColor: PropTypes.string,
    theme: PropTypes.string,
};

export default BlockHotspot;

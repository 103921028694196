import React from 'react';

import Button from 'components/ui/Button';

import styles from './BubbleButton.module.scss';

const BubbleButton = props => {
    const buttonProps = {
        ...props,
    };

    return (
        <div className={styles.root}>
            <Button {...buttonProps} className={styles.button}>
                <label className="sr-only">Open Overlay</label>
                <svg
                    className={styles.highlight}
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        opacity=".1"
                        d="M2.7178 15.2484C-2.79431 14.6973 1.4583 4.44227 7.63424 1.78576C13.3187 -0.659332 16.2175 0.461832 16.2175 2.31261C16.2175 4.16338 14.0752 1.6635 8.50263 6.6635C3.50263 11.1497 7.21872 15.6985 2.7178 15.2484Z"
                        fill="#ffffff"
                    />
                </svg>
                <svg
                    className={styles.icon}
                    viewBox="0 0 10 10"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.496453 5.64072L4.28772 5.63392L4.28081 9.48058L4.28045 9.6813L4.48117 9.68094L5.4202 9.67926L5.61948 9.6789L5.61984 9.47962L5.62675 5.63151L9.38489 5.62476L9.58417 5.62441L9.58453 5.42512L9.58621 4.48622L9.58657 4.2855L9.38585 4.28586L5.62915 4.2926L5.6358 0.591179L5.63616 0.39046L5.43544 0.390821L4.49641 0.392507L4.29713 0.392864L4.29677 0.592147L4.29012 4.29501L0.49742 4.30182L0.298138 4.30218L0.29778 4.50146L0.296094 5.44037L0.295734 5.64108L0.496453 5.64072Z"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="0.4"
                    />
                </svg>
            </Button>
        </div>
    );
};

export default BubbleButton;
